export const CognitoFormLayout = () => import('../../components/CognitoFormLayout.vue' /* webpackChunkName: "components/cognito-form-layout" */).then(c => wrapFunctional(c.default || c))
export const ContactAssignmentsGrid = () => import('../../components/ContactAssignmentsGrid.vue' /* webpackChunkName: "components/contact-assignments-grid" */).then(c => wrapFunctional(c.default || c))
export const DropdownMenu = () => import('../../components/DropdownMenu.vue' /* webpackChunkName: "components/dropdown-menu" */).then(c => wrapFunctional(c.default || c))
export const GeneralHoverMessage = () => import('../../components/GeneralHoverMessage.vue' /* webpackChunkName: "components/general-hover-message" */).then(c => wrapFunctional(c.default || c))
export const GoalsGrid = () => import('../../components/GoalsGrid.vue' /* webpackChunkName: "components/goals-grid" */).then(c => wrapFunctional(c.default || c))
export const MetersGrid = () => import('../../components/MetersGrid.vue' /* webpackChunkName: "components/meters-grid" */).then(c => wrapFunctional(c.default || c))
export const NotesGrid = () => import('../../components/NotesGrid.vue' /* webpackChunkName: "components/notes-grid" */).then(c => wrapFunctional(c.default || c))
export const OrdinanceDetailGrid = () => import('../../components/OrdinanceDetailGrid.vue' /* webpackChunkName: "components/ordinance-detail-grid" */).then(c => wrapFunctional(c.default || c))
export const TroveAutoComplete = () => import('../../components/TroveAutoComplete.vue' /* webpackChunkName: "components/trove-auto-complete" */).then(c => wrapFunctional(c.default || c))
export const ZionButton = () => import('../../components/ZionButton.vue' /* webpackChunkName: "components/zion-button" */).then(c => wrapFunctional(c.default || c))
export const ZionCircleGraph = () => import('../../components/ZionCircleGraph.vue' /* webpackChunkName: "components/zion-circle-graph" */).then(c => wrapFunctional(c.default || c))
export const ZionEditLayout = () => import('../../components/ZionEditLayout.vue' /* webpackChunkName: "components/zion-edit-layout" */).then(c => wrapFunctional(c.default || c))
export const ZionEditSection = () => import('../../components/ZionEditSection.vue' /* webpackChunkName: "components/zion-edit-section" */).then(c => wrapFunctional(c.default || c))
export const ZionFooter = () => import('../../components/ZionFooter.vue' /* webpackChunkName: "components/zion-footer" */).then(c => wrapFunctional(c.default || c))
export const ZionForm = () => import('../../components/ZionForm.vue' /* webpackChunkName: "components/zion-form" */).then(c => wrapFunctional(c.default || c))
export const ZionGrid = () => import('../../components/ZionGrid.vue' /* webpackChunkName: "components/zion-grid" */).then(c => wrapFunctional(c.default || c))
export const ZionLayout = () => import('../../components/ZionLayout.vue' /* webpackChunkName: "components/zion-layout" */).then(c => wrapFunctional(c.default || c))
export const ZionLoading = () => import('../../components/ZionLoading.vue' /* webpackChunkName: "components/zion-loading" */).then(c => wrapFunctional(c.default || c))
export const ZionMultiSelect = () => import('../../components/ZionMultiSelect.vue' /* webpackChunkName: "components/zion-multi-select" */).then(c => wrapFunctional(c.default || c))
export const ZionNav = () => import('../../components/ZionNav.vue' /* webpackChunkName: "components/zion-nav" */).then(c => wrapFunctional(c.default || c))
export const ZionSaveButton = () => import('../../components/ZionSaveButton.vue' /* webpackChunkName: "components/zion-save-button" */).then(c => wrapFunctional(c.default || c))
export const ZionSection = () => import('../../components/ZionSection.vue' /* webpackChunkName: "components/zion-section" */).then(c => wrapFunctional(c.default || c))
export const ZionTableauDownload = () => import('../../components/ZionTableauDownload.vue' /* webpackChunkName: "components/zion-tableau-download" */).then(c => wrapFunctional(c.default || c))
export const ZionTableauEmbed = () => import('../../components/ZionTableauEmbed.vue' /* webpackChunkName: "components/zion-tableau-embed" */).then(c => wrapFunctional(c.default || c))
export const ZionTableauPage = () => import('../../components/ZionTableauPage.vue' /* webpackChunkName: "components/zion-tableau-page" */).then(c => wrapFunctional(c.default || c))
export const ZionToggle = () => import('../../components/ZionToggle.vue' /* webpackChunkName: "components/zion-toggle" */).then(c => wrapFunctional(c.default || c))
export const ZionTree = () => import('../../components/ZionTree.vue' /* webpackChunkName: "components/zion-tree" */).then(c => wrapFunctional(c.default || c))
export const ZionUpload = () => import('../../components/ZionUpload.vue' /* webpackChunkName: "components/zion-upload" */).then(c => wrapFunctional(c.default || c))
export const ZionVSelect = () => import('../../components/ZionVSelect.vue' /* webpackChunkName: "components/zion-v-select" */).then(c => wrapFunctional(c.default || c))
export const EnergyColDefs = () => import('../../components/energyColDefs.js' /* webpackChunkName: "components/energy-col-defs" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
